import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesCompras() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_compras ?(
      <div className="container">
        <br />
        <br />
        <Row >
        {/* <Col md={4} align="center">
            {user.menu_proveedores ?(
            <Button href="/Proveedores" className="botonesMenu" color="success">
            <i class="fas fa-dolly fa-7x"></i>
              <br />
              <br />
              Proveedores
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-dolly fa-7x"></i>
              <br />
              <br />
              Proveedores
            </Button> }
          </Col> */}
        <Col md={4} align="center">
          {user.menu_oc ?(
            <Button href="/ListadoOrdenesCompra" className="botonesMenu" color="success">
              <i class="fas fa-shopping-basket fa-7x"></i>
              <br />
              <br />
              Ordenes de Compra
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-shopping-basket fa-7x"></i>
          <br />
          <br />
          Ordenes de Compra
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_compras ?(
            <Button href="/ListadoCompras" className="botonesMenu" color="success">
              <i class="fas fa-cart-plus fa-7x"></i>
              <br />
              <br />
              Recepción
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-cart-plus fa-7x"></i>
          <br />
          <br />
          Recepción
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_compras ?(
            <Button href="/ListadoComprasFaltante" className="botonesMenu" color="success">
              <i class="fas fa-minus-circle fa-7x"></i>
              <br />
              <br />
              Faltantes en <br /> Compras
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-minus-circle fa-7x"></i>
          <br />
          <br />
          Faltantes en <br /> Compras
        </Button> }
          </Col>
        </Row>
        <br/>
        <Row>
        <Col md={4} align="center">
          {user.menu_devolucionesProveedores ?(
            <Button href="/ListadoDevolucionesProveedores" className="botonesMenu" color="success">
              <i class="fas fa-undo-alt fa-7x"></i>
              <br />
              <br />
              Devoluciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-undo-alt fa-7x"></i>
          <br />
          <br />
          Devoluciones
        </Button> }
          </Col> 
          <Col md={4} align="center">
            {user.menu_compras ?(
              <Button
                href="/CorteDiario"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-cut fa-7x"></i>
                <br />
                <br />
                Reporte Telas
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-cut fa-7x"></i>
            <br />
            <br />
            Reporte Telas
          </Button> }
            </Col>

            <Col md={4} align="center">
          {user.menu_compras ?(
            <Button href="/ReporteComprasMaterial" className="botonesMenu" color="success">
              <i class="fas fa-cart-plus fa-7x"></i>
              <br />
              <br />
              Reporte Compras <br /> Material
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-cart-plus fa-7x"></i>
          <br />
          <br />
          Reporte Compras <br /> Material
        </Button> }
          </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCompras;
