import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { v4 as uuidv4 } from "uuid";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import Status from "../../Status";
import moment from "moment";

function ListadoCortes() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_CORTES = process.env.REACT_APP_URL_CORTES;
  const URL_ARTICULOS_CORTES = process.env.REACT_APP_URL_ARTICULOS_CORTES;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_PROCESOS_SALIDA = process.env.REACT_APP_URL_PROCESOS_SALIDA;
  const URL_ARTICULOS_PROCESOS_SALIDA =
    process.env.REACT_APP_URL_ARTICULOS_PROCESOS_SALIDA;
  const URL_PROCESOS_ENTRADA = process.env.REACT_APP_URL_PROCESOS_ENTRADA;
  const URL_ARTICULOS_PROCESOS_ENTRADA =
    process.env.REACT_APP_URL_ARTICULOS_PROCESOS_ENTRADA;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;
  const URL_HABILITACION = process.env.REACT_APP_URL_HABILITACION;
  const URL_HABILITACION_CORTES = process.env.REACT_APP_URL_HABILITACION_CORTES;
  const URL_DETALLE_HABILITACION_CORTES =
    process.env.REACT_APP_URL_DETALLE_HABILITACION_CORTES;
  const URL_FALTANTES_PROVEEDORES =
    process.env.REACT_APP_URL_FALTANTES_PROVEEDORES;
  const URL_ENTRADAS_ALMACEN = process.env.REACT_APP_URL_ENTRADAS_ALMACEN;
  const URL_ENTRADAS_ALMACEN_ACUMULADOS =
    process.env.REACT_APP_URL_ENTRADAS_ALMACEN_ACUMULADOS;
  const ALMACEN = process.env.REACT_APP_ALMACEN;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 60);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [cortes, setCortes] = useState([]);

  const [articulos, setArticulos] = useState([]);
  const [areas, setAreas] = useState([]);
  const [areasEntrada, setAreasEntrada] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [selectedCorte, setSelectedCorte] = useState("");
  const [selectedCorteNumero, setSelectedCorteNumero] = useState("");
  const [modalProcesosSalida, setModalProcesosSalida] = useState(false);
  const toggleProcesosSalida = () =>
    setModalProcesosSalida(!modalProcesosSalida);
  const [articulosCortes, setArticulosCortes] = useState([]);
  const [articulosProcesosSalida, setArticulosProcesosSalida] = useState([]);
  const [articulosUnicos, setArticulosUnicos] = useState([]);
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [idCatalogoProcesos, setIdCatalogoProcesos] = useState("");
  const [nombreProcesosSalida, setNombreProcesosSalida] = useState("");
  const [idArticulo, setIdArticulo] = useState("");
  const [nombreArticulo, setNombreArticulo] = useState("");
  const [idPedido, setIdPedido] = useState("");
  const [numeroPedido, setNumeroPedido] = useState("");
  const [modalFuncionProcesosSalida, setModalFuncionProcesosSalida] =
    useState(false);
  const toggleFuncionProcesosSalida = () =>
    setModalFuncionProcesosSalida(!modalFuncionProcesosSalida);
  const [fechaProceso, setFechaProceso] = useState(endDate);
  const [fechaCompromisoProceso, setFechaCompromisoProceso] = useState(endDate);
  const [maquileroProceso, setMaquileroProceso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [precioProceso, setPrecioProceso] = useState(0);
  const [proveedores, setProveedores] = useState([]);
  const [totalPiezas, setTotalPiezas] = useState(0);
  const [totalPiezasFaltantes, setTotalPiezasFaltantes] = useState(0);
  const [totalPiezasSegundas, setTotalPiezasSegundas] = useState(0);
  const [totalGeneral, setTotalGeneral] = useState(0);
  const [fechaCancelacion, setFechaCancelacion] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);

  const [fecha, setFecha] = useState(endDate);
  const [plazo, setPlazo] = useState("");

  const [numero_corte, setNumeroCorte] = useState("");

  const [idEdit, setIdEdit] = useState("");

  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [articulosEntrada, setArticulosEntrada] = useState([]);
  const [articulosUnicosEntrada, setArticulosUnicosEntrada] = useState([]);
  const [nombreProcesosEntrada, setNombreProcesosEntrada] = useState("");
  const [articulosProcesosEntrada, setArticulosProcesosEntrada] = useState([]);
  const [selectedProcesoSalida, setSelectedProcesoSalida] = useState("");

  const [modalProcesosEntrada, setModalProcesosEntrada] = useState(false);
  const toggleProcesosEntrada = () =>
    setModalProcesosEntrada(!modalProcesosEntrada);
  const [modalFuncionProcesosEntrada, setModalFuncionProcesosEntrada] =
    useState(false);
  const toggleFuncionProcesosEntrada = () =>
    setModalFuncionProcesosEntrada(!modalFuncionProcesosEntrada);

  const [entregaHabilitacion, setEntregaHabilitacion] = useState(false);
  const [habilitacion, setHabilitacion] = useState([]);

  const [modalFaltante, setModalFaltante] = useState(false);
  const toggleFaltante = () => setModalFaltante(!modalFaltante);
  const [precioFaltante, setPrecioFaltante] = useState(0);
  const [precioFaltanteHab, setPrecioFaltanteHab] = useState(0);
  const [cantidadFaltante, setCantidadFaltante] = useState(0);
  const [cantidadFaltanteHab, setCantidadFaltanteHab] = useState(0);
  const [totalFaltante, setTotalFaltante] = useState(0);
  const [totalTotalFaltante, setTotalTotalFaltante] = useState(0);
  const [totalFaltanteHab, setTotalFaltanteHab] = useState(0);
  const [procesoSalidaFaltante, setProcesoSalidaFaltante] = useState("");
  const [idMaquileroFaltante, setIdMaquileroFaltante] = useState("");
  const [fechaFaltante, setFechaFaltante] = useState(endDate);

  const [articuloProceso, setArticuloProceso] = useState("");

  const [modalEntradaAlmacen, setModalEntradaAlmacen] = useState(false);
  const toggleEntradaAlmacen = () =>
    setModalEntradaAlmacen(!modalEntradaAlmacen);
  const [articulosCortesEntrada, setArticulosCortesEntrada] = useState([]);
  const [articulosUnicosEntradaAlma, setArticulosUnicosEntradaAlma] = useState(
    []
  );
  const [codigoSAT, setCodigoSAT] = useState("");
  const [codigoUnidad, setCodigoUnidad] = useState("");
  const [totalPiezasEntradaAlma, setTotalPiezasEntradaAlma] = useState(0);
  const [totalPiezasEntradaSegundasAlma, setTotalPiezasEntradaSegundasAlma] =
    useState(0);
  const [fechaEntradaAlma, setFechaEntradaAlma] = useState(endDate);

  const [PDFPS, setPDFPS] = useState([]);
  const [modalPDFPS, setModalPDFPS] = useState(false);
  const togglePDFPS = () => setModalPDFPS(!modalPDFPS);

  const [PDFPE, setPDFPE] = useState([]);
  const [modalPDFPE, setModalPDFPE] = useState(false);
  const togglePDFPE = () => setModalPDFPE(!modalPDFPE);

  const [modalHabilitacion, setModalHabilitacion] = useState(false);
  const toggleHabilitacion = () => setModalHabilitacion(!modalHabilitacion);
  const [idProcesosSalidaHab, setIdProcesosSalidaHab] = useState("");
  const [idCorteHab, setIdCorteHab] = useState("");
  const [idPedidoHab, setIdPedidoHab] = useState("");
  const [idProveedorHab, setIdProveedorHab] = useState("");
  const [fechaHab, setFechaHab] = useState(hoy);

  const [foto, setFoto] = useState("");

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      colores: "",
      tallas: "",
      cantidad: 0,
      faltantes: 0,
      segundas: 0,
      total: 0,
      articulosPedido: "",
    },
  ]);

  const [inputFieldsHabilitacion, setInputFieldsHabilitacion] = useState([
    {
      id: uuidv4(),
      idHabilitacion: "",
      cantidad: 0,
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_CORTES}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCortes = response.data;
        // Array para pagination
        let arrayTabla = allCortes
          .sort((a, b) => (a.idCorte > b.idCorte ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idCorte,
              fecha: a.fecha,
              total_cantidad: a.total_cantidad,
              ordenCorte: a.ordenesCorte[0].numero,
              fechaCancelacionPedido:
                a.ordenesCorte[0].pedidos[0].fecha_cancelacion,
              cliente:
                a.ordenesCorte[0].pedidos[0].clientes[0].nombre_comercial,
              // marca: a.ordenesCorte[0].pedidos[0].articulos[0].marcas[0].name,
              articulo:
                "Cod: " +
                a.ordenesCorte[0].pedidos[0].articulos[0].codigoCliente +
                " / Ref: " +
                a.ordenesCorte[0].pedidos[0].articulos[0].codigo +
                " / Desc: " +
                a.ordenesCorte[0].pedidos[0].articulos[0].descripcion,
              cantidadPedido: a.ordenesCorte[0].pedidos[0].total_piezas,
              mesa: a.mesa,
              lienzos: a.lienzos,
              empalmes: a.empalmes,
              retaceria: a.retaceria,
              largoTrazo: a.largoTrazo,
              tiempoInicio: a.tiempoInicio,
              tiempoFin: a.tiempoFin,
              responsable1:
                a.responsable1[0].nombre + " " + a.responsable1[0].apellido,
              responsable2:
                a.responsable2[0].nombre + " " + a.responsable2[0].apellido,
              status: a.status,
              observaciones: a.observaciones,
              idArticulo: a.ordenesCorte[0].pedidos[0].articulos[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setCortes(allCortes);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_HABILITACION, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allHabilitacion = response.data;
        // Array para pagination
        let arrayTabla = allHabilitacion
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              nombre: a.nombre,
              unidad: a.unidad,
              observaciones: a.observaciones,
              descripcion: a.descripcion,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setHabilitacion(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  const ListaCortes = comments.sort((a, b) => (a.idCorte < b.idCorte ? 1 : -1));

  function PDFTabla() {
    const data = ListaCortes.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return [a.numero, a.fecha, a.ordenCorte, a.mesa, a.total_cantidad];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Cortes`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Orden de Corte", "Mesa", "Cantidad"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    // doc.save(`Cortes.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = ListaCortes.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          OrdenCorte: a.ordenCorte,
          Mesa: a.mesa,
          TotalCantidad: a.total_cantidad,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoCortes";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoCortes",
        sheetFilter: ["Numero", "Fecha", "OrdenCorte", "Mesa", "TotalCantidad"],
        sheetHeader: ["Numero", "Fecha", "OrdenCorte", "Mesa", "TotalCantidad"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaCortes.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return [a.numero, a.fecha, a.ordenCorte, a.mesa, a.total_cantidad];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Cortes`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Orden de Corte", "Mesa", "Cantidad"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Cortes",
          email: mailTo,
          fileName: "ListadoCortes.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Cortes.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Orden Corte", field: "ordenCorte", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha Creacion", field: "fecha", sortable: true },
    { name: "Mesa", field: "mesa", sortable: true },

    { name: "Cliente", field: "cliente", sortable: true },
    { name: "Articulo", field: "articulo", sortable: true },
    // { name: "Marca", field: "marca", sortable: true },

    {
      name: "Fecha Cancelacion",
      field: "fechaCancelacionPedido",
      sortable: true,
    },
    { name: "Cantidad Cortada", field: "total_cantidad", sortable: true },

    { name: "Cantidad Pedido", field: "cantidadPedido", sortable: true },

    { name: "PDF Corte", field: "PDF", sortable: false },
    { name: "Salida Procesos", field: "Salida Procesos", sortable: false },
    { name: "Entrada Procesos", field: "Entrada Procesos", sortable: false },
    { name: "Entrada Almacen", field: "Entrada Almacen", sortable: false },
    { name: "Status", field: "status", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          // comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          comment.numero.toString().includes(search) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.ordenCorte.toString().includes(search) ||
          comment.cantidadPedido.toString().includes(search) ||
          comment.mesa.toString().includes(search)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "numero" &&
      sorting.field != "total_cantidad" &&
      sorting.field != "cantidadPedido" &&
      sorting.field != "ordenCorte" &&
      sorting.field != "mesa"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "cantidadPedido" ||
        sorting.field == "ordenCorte" ||
        sorting.field == "numero" ||
        sorting.field == "mesa")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "cantidadPedido" ||
        sorting.field == "ordenCorte" ||
        sorting.field == "numero" ||
        sorting.field == "mesa")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  async function PDFOC(
    numero,
    fecha,
    ordenCorte,
    mesa,
    total_cantidad,
    idPDFOC,
    lienzos,
    empalmes,
    retaceria,
    largoTrazo,
    tiempoInicio,
    tiempoFin,
    responsable1,
    responsable2,
    observaciones
  ) {
    await axios
      .get(`${URL_ARTICULOS_CORTES}/corte/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosCorte = response.data;
        let arrayTabla = allArticulosCorte
          .map((a) => {
            return {
              id: a._id,
              articulo:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .codigoCliente +
                " " +
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .codigo +
                " " +
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .descripcion,
              color:
                a.articulosOrdenesCorte[0].articulosPedido[0].colores[0].name,
              talla:
                a.articulosOrdenesCorte[0].articulosPedido[0].tallas[0].name,
              total_cantidad: a.total_cantidad,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = arrayTabla
          .sort(
            (a, b) =>
              a.color.localeCompare(b.color) || a.talla.localeCompare(b.talla)
          )
          .map((a) => {
            return [
              a.articulo,
              a.color,
              a.talla,
              new Intl.NumberFormat("en-US").format(a.total_cantidad),
            ];
          });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Corte # ${numero}`, 20, 18);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Orden de Corte ${ordenCorte}`, 20, 31);
        doc.text(`Responsables: ${responsable1} / ${responsable2}`, 20, 37);
        doc.text(`Inicio: ${tiempoInicio} / Fin: ${tiempoFin}`, 20, 43);
        doc.text(`Observaciones: ${observaciones}`, 20, 49);
        doc.autoTable({
          head: [["Articulo", "Color", "Talla", "Cantidad"]],
          body: dataPDFLimpia,
          startY: 55,
          foot: [
            [
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US").format(total_cantidad),
            ],
          ],
          showFoot: "lastPage",
        });
        // doc.save(`Corte-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function procesosSalida(id, numero) {
    setEntregaHabilitacion(false);
    setInputFieldsHabilitacion([
      {
        id: uuidv4(),
        idHabilitacion: "",
        cantidad: 0,
      },
    ]);
    axios
      .get(`${URL_ARTICULOS_CORTES}/corte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .map((a) => {
            return {
              id: a._id,
              articuloNombre:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .codigoCliente +
                " " +
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .codigo +
                " " +
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .descripcion,
              idArticulo:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]._id,
              total_cantidad: a.total_cantidad,
              catalogoProcesos:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .catalogoProcesos,
              idPedido: a.pedidos[0]._id,
              numeroPedido: a.pedidos[0].idPedido,
              articulosPedido:
                a.articulosOrdenesCorte[0].articulosPedido[0]._id,
                fechaCancelacion: a.pedidos[0].fecha_cancelacion
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = Object.values(arrayTabla);
        setArticulosCortes(data);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            total_cantidad: 0,
          };
          groups[val].idArticulo = item.idArticulo;
          groups[val].idPedido = item.idPedido;
          groups[val].numeroPedido = item.numeroPedido;
          groups[val].articuloNombre = item.articuloNombre;
          groups[val].total_cantidad += item.total_cantidad;
          groups[val].catalogoProcesos = item.catalogoProcesos;
          groups[val].fechaCancelacion = item.fechaCancelacion;
          return groups;
        }, []);
        let dataFinalAgru = Object.values(agrupado);
        setArticulosUnicos(dataFinalAgru);
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedCorte(id);
    setSelectedCorteNumero(numero);
    toggleProcesosSalida();
  }

  function funcionProcesosSalida(
    idCatalogoProcesos,
    nombreProcesosSalida,
    idArticulo,
    nombreArticulo,
    idPedido,
    numeroPedido,
    fechaCancelacion
  ) {
    setArticuloProceso(idArticulo);
    axios
      .get(`${URL_ARTICULOS_CORTES}/corte/${selectedCorte}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosProcesosSalida = response.data;
        setArticulosProcesosSalida(allArticulosProcesosSalida);

        let arrayInputFields = allArticulosProcesosSalida
          .map((a) => {
            if (
              idArticulo ==
              a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]._id
            ) {
              return {
                id: uuidv4(),
                colores:
                  a.articulosOrdenesCorte[0].articulosPedido[0].colores[0].name,
                tallas:
                  a.articulosOrdenesCorte[0].articulosPedido[0].tallas[0].name,
                cantidad: a.total_cantidad,
                faltantes: 0,
                segundas: 0,
                total: 0,
                articulosPedido:
                  a.articulosOrdenesCorte[0].articulosPedido[0]._id,
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataIF = Object.values(arrayInputFields);

        setInputFields(dataIF);

        let cantTotal = dataIF.map((c) => parseFloat(c.cantidad));
        let TC = cantTotal.reduce((t, total, index) => t + total, 0);
        setTotalPiezas(TC);
      })
      .catch((err) => {
        console.log(err);
      });

    setIdCatalogoProcesos(idCatalogoProcesos);
    setNombreProcesosSalida(nombreProcesosSalida);
    setIdArticulo(idArticulo);
    setNombreArticulo(nombreArticulo);
    setIdPedido(idPedido);
    setNumeroPedido(numeroPedido);
    setFechaCancelacion(fechaCancelacion);
    setModalProcesosSalida(false);
    toggleFuncionProcesosSalida();
  }

  const handleChangeInputPrecio = (event) => {
    const newInputFields = inputFields.map((i) => {
      // i[event.target.name] = event.target.value;
      i.total = i.cantidad * event.target.value;
      return i;
    });
    setPrecioProceso(event.target.value);
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.total = parseFloat(event.target.value) * parseFloat(precioProceso);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidadFDS = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cantTotal = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cantTotal.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TC);

    let cantTotalFaltantes = inputFields.map((c) => parseFloat(c.faltantes));
    let TF = cantTotalFaltantes.reduce((t, total, index) => t + total, 0);
    setTotalPiezasFaltantes(TF);

    let cantTotalSegundas = inputFields.map((c) => parseFloat(c.segundas));
    let TS = cantTotalSegundas.reduce((t, total, index) => t + total, 0);
    setTotalPiezasSegundas(TS);

    let impTotal = inputFields.map((c) => parseFloat(c.total));
    let TG = impTotal.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  }

  const saveSalidaProceso = async (event) => {
    event.preventDefault();
    if (fechaProceso != "" && fechaCompromisoProceso != "") {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos = 0;
      if (entregaHabilitacion) {
        totalRollos = inputFields.length + inputFieldsHabilitacion.length;
      } else {
        totalRollos = inputFields.length;
      }
      try {
        await axios
          .post(
            URL_PROCESOS_SALIDA,
            {
              catalogoProcesos: idCatalogoProcesos,
              proveedores: maquileroProceso,
              pedidos: idPedido,
              fecha: fechaProceso,
              fechaCompromiso: fechaCompromisoProceso,
              observaciones,
              status: "Abierto",
              totalGeneral,
              totalPiezas,
              precio: precioProceso,
              cortes: selectedCorte,
              articulos: articuloProceso,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            inputFields.map((a) => {
              if (a.cantidad != 0) {
                axios
                  .post(
                    URL_ARTICULOS_PROCESOS_SALIDA,
                    {
                      procesosSalida: data.data._id,
                      articulosPedido: a.articulosPedido,
                      pedidos: idPedido,
                      cantidadSalida: a.cantidad,
                      cantidadEntrada: 0,
                      faltantes: a.cantidad,
                      cortes: selectedCorte,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Salida de Proceso",
                            detalle: `Pedido ${numeroPedido} Articulo ${articulosCortes[0].articuloNombre}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Salida de Proceso",
                        detalle: `Pedido ${numeroPedido} Articulo ${articulosCortes[0].articuloNombre}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
            setValidaBoton(true);
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  //Procesos Entrada

  function procesosEntrada(id, numero) {
    axios
      .get(`${URL_ARTICULOS_PROCESOS_SALIDA}/corte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .map((a) => {
            return {
              id: a._id,
              articuloNombre:
                a.articulosPedido[0].articulos[0].codigoCliente +
                " " +
                a.articulosPedido[0].articulos[0].codigo +
                " " +
                a.articulosPedido[0].articulos[0].descripcion,
              idArticulo: a.articulosPedido[0].articulos[0]._id,
              catalogoProcesos: a.procesosSalida[0].catalogoProcesos[0].name,
              idCatalogoProcesos: a.procesosSalida[0].catalogoProcesos[0]._id,
              idPedido: a.pedidos[0]._id,
              numeroPedido: a.pedidos[0].idPedido,
              articulosPedido: a.articulosPedido[0]._id,
              cantidadEntrada: a.cantidadEntrada,
              cantidadSalida: a.cantidadSalida,
              status: a.procesosSalida[0].status,
              fecha: a.procesosSalida[0].fecha,
              fechaCompromiso: a.procesosSalida[0].fechaCompromiso,
              precio: a.procesosSalida[0].precio,
              maquilero: a.procesosSalida[0].proveedores[0].nombre_comercial,
              idMaquilero: a.procesosSalida[0].proveedores[0]._id,
              procesosSalida: a.procesosSalida[0]._id,
              faltantes: a.faltantes,
              faltantesHab: a.faltantesHab,
              segundas: a.segundas,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = Object.values(arrayTabla);
        setArticulosEntrada(data);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["idArticulo"] + item["procesosSalida"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            procesosSalida: item.procesosSalida,
            cantidadSalida: 0,
            cantidadEntrada: 0,
            faltantes: 0,
            faltantesHab: 0,
            segundas: 0,
          };
          groups[val].idArticulo = item.idArticulo;
          groups[val].idPedido = item.idPedido;
          groups[val].numeroPedido = item.numeroPedido;
          groups[val].articuloNombre = item.articuloNombre;
          groups[val].cantidadSalida += item.cantidadSalida;
          groups[val].cantidadEntrada += item.cantidadEntrada;
          groups[val].catalogoProcesos = item.catalogoProcesos;
          groups[val].idCatalogoProcesos = item.idCatalogoProcesos;
          groups[val].status = item.status;
          groups[val].fecha = item.fecha;
          groups[val].fechaCompromiso = item.fechaCompromiso;
          groups[val].precio = item.precio;
          groups[val].maquilero = item.maquilero;
          groups[val].idMaquilero = item.idMaquilero;
          groups[val].procesosSalida = item.procesosSalida;
          groups[val].faltantes += item.faltantes;
          groups[val].faltantesHab += item.faltantesHab;
          groups[val].segundas += item.segundas;
          return groups;
        }, []);
        let dataFinalAgru = Object.values(agrupado);
        setArticulosUnicosEntrada(dataFinalAgru);
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedCorte(id);
    setSelectedCorteNumero(numero);
    toggleProcesosEntrada();
  }

  function funcionProcesosEntrada(
    idCatalogoProcesos,
    nombreProcesosEntrada,
    idArticulo,
    nombreArticulo,
    idPedido,
    numeroPedido,
    procesosSalida
  ) {
    axios
      .get(`${URL_ARTICULOS_PROCESOS_SALIDA}/proceso/${procesosSalida}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosProcesosEntrada = response.data;
        setArticulosProcesosEntrada(allArticulosProcesosEntrada);
        setMaquileroProceso(
          allArticulosProcesosEntrada[0].procesosSalida[0].proveedores[0]._id
        );
        setPrecioProceso(
          allArticulosProcesosEntrada[0].procesosSalida[0].precio
        );

        let arrayInputFields = allArticulosProcesosEntrada.map((a) => {
          return {
            id: uuidv4(),
            colores: a.articulosPedido[0].colores[0].name,
            tallas: a.articulosPedido[0].tallas[0].name,
            cantidad: a.faltantes,
            faltantes: 0,
            segundas: 0,
            total:
              parseFloat(a.faltantes) * parseFloat(a.procesosSalida[0].precio),
            articulosPedido: a.articulosPedido[0]._id,
            articulosProcesosSalida: a._id,
          };
        });

        let dataIF = Object.values(arrayInputFields);

        setInputFields(dataIF);

        let cantTotal = dataIF.map((c) => parseFloat(c.cantidad));
        let TC = cantTotal.reduce((t, total, index) => t + total, 0);
        setTotalPiezas(TC);

        let impTotal = dataIF.map((c) => parseFloat(c.total));
        let TG = impTotal.reduce((t, total, index) => t + total, 0);
        setTotalGeneral(TG);
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedProcesoSalida(procesosSalida);
    setIdCatalogoProcesos(idCatalogoProcesos);
    setNombreProcesosEntrada(nombreProcesosEntrada);
    setIdArticulo(idArticulo);
    setNombreArticulo(nombreArticulo);
    setIdPedido(idPedido);
    setNumeroPedido(numeroPedido);
    setModalProcesosEntrada(false);
    toggleFuncionProcesosEntrada();
  }

  const saveEntradaProceso = async (event) => {
    event.preventDefault();
    if (fechaProceso != "") {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos = inputFields.length;
      try {
        await axios
          .post(
            URL_PROCESOS_ENTRADA,
            {
              catalogoProcesos: idCatalogoProcesos,
              proveedores: maquileroProceso,
              pedidos: idPedido,
              fecha: fechaProceso,
              observaciones,
              totalGeneral,
              totalPiezas,
              totalPiezasFaltantes,
              totalPiezasSegundas,
              precio: precioProceso,
              cortes: selectedCorte,
              procesosSalida: selectedProcesoSalida,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            axios.post(
              URL_NOTAS_CXP,
              {
                procesosEntrada: data.data._id,
                proveedores: maquileroProceso,
                total_cantidad: totalPiezas,
                total_costo: totalGeneral,
                saldo: totalGeneral,
                fecha: fechaProceso,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );

            inputFields.map((a) => {
              if (a.cantidad != 0 || a.faltantes != 0 || a.segundas != 0) {
                axios
                  .post(
                    URL_ARTICULOS_PROCESOS_ENTRADA,
                    {
                      procesosEntrada: data.data._id,
                      articulosPedido: a.articulosPedido,
                      articulosProcesosSalida: a.articulosProcesosSalida,
                      pedidos: idPedido,
                      cantidadEntrada: a.cantidad,
                      cantidadFaltantes: a.faltantes,
                      cantidadSegundas: a.segundas,
                      cortes: selectedCorte,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Entrada de Proceso",
                            detalle: `Pedido ${numeroPedido}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Entrada de Proceso",
                        detalle: `Pedido ${numeroPedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  function jalaHabilitacion(
    id,
    numero,
    catalogosProcesosName,
    proveedoresName,
    idProveedor,
    pedido,
    idPedido,
    fechaCompromiso,
    totalPiezas,
    precio,
    totalGeneral,
    fecha,
    idCorte,
    numeroCorte
  ) {
    setIdProcesosSalidaHab(id);
    setIdCorteHab(idCorte);
    setIdPedidoHab(idPedido);
    setIdProveedorHab(idProveedor);
    toggleHabilitacion();
  }

  function saveHabilitacion(event) {
    event.preventDefault();
    let totalRollos = inputFieldsHabilitacion.length;
    axios
      .post(
        URL_HABILITACION_CORTES,
        {
          cortes: idCorteHab,
          pedidos: idPedidoHab,
          proveedores: idProveedorHab,
          procesosSalida: idProcesosSalidaHab,
          fecha: fechaHab,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((data2) => {
        inputFieldsHabilitacion.map((a) => {
          if (a.cantidad != 0) {
            axios
              .post(
                URL_DETALLE_HABILITACION_CORTES,
                {
                  cantidad: a.cantidad,
                  habilitacionCortes: data2.data._id,
                  cortes: idCorteHab,
                  pedidos: idPedidoHab,
                  proveedores: idProveedorHab,
                  procesosSalida: idProcesosSalidaHab,
                  habilitacion: a.idHabilitacion,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Salida de Habilitacion",
                        detalle: `Pedido ${idPedidoHab}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              });
          } else {
            totalRollos = totalRollos - 1;
            if (totalRollos == 0) {
              axios
                .post(
                  URL_LOGS,
                  {
                    tipo: "Salida de Habilitacion",
                    detalle: `Pedido ${idPedidoHab}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            }
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
      });
  }

  const handleChangeInputHabilitacion = (id, event) => {
    const newInputFieldsHabilitacion = inputFieldsHabilitacion.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsHabilitacion(newInputFieldsHabilitacion);
  };

  const handleRemoveFieldsHabilitaccion = (id) => {
    const values = [...inputFieldsHabilitacion];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsHabilitacion(values);
  };

  const handleAddFieldsHabilitacion = () => {
    setInputFieldsHabilitacion([
      ...inputFieldsHabilitacion,
      {
        id: uuidv4(),
        idHabilitacion: "",
        cantidad: 0,
      },
    ]);
  };
  function cerrarProcesosEntrada(
    procesosSalida,
    faltantes,
    faltantesHab,
    maquilero
  ) {
    if (faltantes > 0 || faltantesHab > 0) {
      setCantidadFaltante(faltantes);
      setCantidadFaltanteHab(faltantesHab);
      setProcesoSalidaFaltante(procesosSalida);
      setIdMaquileroFaltante(maquilero);
      setTotalFaltante(0);
      toggleFaltante();
    } else {
      Swal.fire({
        title: "Estas seguro?",
        text: "Se cerrara el proceso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, cerrar!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .patch(
              `${URL_PROCESOS_SALIDA}/${procesosSalida}`,
              {
                status: "Cerrado",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then((response) => {
              axios
                .post(
                  URL_LOGS,
                  {
                    tipo: "Cerrar Proceso",
                    detalle: `Proceso ${procesosSalida}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Se cerro",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        }
      });
    }
  }

  function saveFaltantes(event) {
    event.preventDefault();
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cerrara el proceso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, cerrar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PROCESOS_SALIDA}/${procesoSalidaFaltante}`,
            {
              status: "Cerrado",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios
              .post(
                URL_FALTANTES_PROVEEDORES,
                {
                  fecha: fechaFaltante,
                  procesosSalida: procesoSalidaFaltante,
                  cantidadFal: cantidadFaltante,
                  precioFal: precioFaltante,
                  totalFal: totalFaltante,
                  cantidadHab: cantidadFaltanteHab,
                  precioHab: precioFaltanteHab,
                  totalHab: totalFaltanteHab,
                  total: totalTotalFaltante,
                  proveedores: idMaquileroFaltante,
                  utilizado: 0,
                  saldo: totalTotalFaltante,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Cerrar Proceso",
                      detalle: `Proceso ${procesoSalidaFaltante}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Se cerro",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function entradaAlmacen(id, numero) {
    axios
      .get(`${URL_ARTICULOS_CORTES}/corte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .map((a) => {
            return {
              id: a._id,
              articuloNombre:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .codigoCliente +
                " " +
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .codigo +
                " " +
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .descripcion,
              idArticulo:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]._id,
              cantidad: a.faltanteRecibir,
              segundas: 0,
              catalogoProcesos:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .catalogoProcesos,
              idTalla:
                a.articulosOrdenesCorte[0].articulosPedido[0].tallas[0]._id,
              talla:
                a.articulosOrdenesCorte[0].articulosPedido[0].tallas[0].name,
              idColor:
                a.articulosOrdenesCorte[0].articulosPedido[0].colores[0]._id,
              color:
                a.articulosOrdenesCorte[0].articulosPedido[0].colores[0].name,
              ubicacion: "",
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = Object.values(arrayTabla);
        setArticulosCortesEntrada(data);
        setIdArticulo(data[0].idArticulo);
        let cantTotal = data.map((c) => parseFloat(c.cantidad));
        let TC = cantTotal.reduce((t, total, index) => t + total, 0);
        setTotalPiezasEntradaAlma(TC);

        let cantTotalSeg = data.map((c) => parseFloat(c.segundas));
        let TCSeg = cantTotalSeg.reduce((t, total, index) => t + total, 0);
        setTotalPiezasEntradaSegundasAlma(TCSeg);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
          };
          groups[val].idArticulo = item.idArticulo;
          return groups;
        }, []);
        let dataFinalAgru = Object.values(agrupado);
        setArticulosUnicosEntradaAlma(dataFinalAgru);
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedCorte(id);
    setSelectedCorteNumero(numero);
    toggleEntradaAlmacen();
  }

  const handleChangeInputCantidadEntradaAlma = (id, event) => {
    const newArticulosCortesEntrada = articulosCortesEntrada.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
      }
      return i;
    });
    setArticulosCortesEntrada(newArticulosCortesEntrada);
    ActualizaTotalesEntradaAlma();
  };

  const handleChangeInputEntradaSegundasAlma = (id, event) => {
    const newArticulosCortesEntrada = articulosCortesEntrada.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
      }
      return i;
    });
    setArticulosCortesEntrada(newArticulosCortesEntrada);
    ActualizaTotalesEntradaAlma();
  };

  const handleChangeInputUbicacion = (id, event) => {
    const newArticulosCortesEntrada = articulosCortesEntrada.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setArticulosCortesEntrada(newArticulosCortesEntrada);
    ActualizaTotalesEntradaAlma();
  };

  function ActualizaTotalesEntradaAlma() {
    let cantTotal = articulosCortesEntrada.map((c) => parseFloat(c.cantidad));
    let TC = cantTotal.reduce((t, total, index) => t + total, 0);
    setTotalPiezasEntradaAlma(TC);

    let cantTotalSeg = articulosCortesEntrada.map((c) =>
      parseFloat(c.segundas)
    );
    let TCSeg = cantTotalSeg.reduce((t, total, index) => t + total, 0);
    setTotalPiezasEntradaSegundasAlma(TCSeg);
  }

  const saveEntradaAlma = async (event) => {
    event.preventDefault();
    if (fechaEntradaAlma != "") {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos = articulosCortesEntrada.length;
      try {
        await axios.patch(
          `${URL_ARTICULOS}/${idArticulo}`,
          {
            codigoSAT,
            codigoUnidad,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
        axios
          .post(
            URL_ENTRADAS_ALMACEN,
            {
              fecha: fechaEntradaAlma,
              cortes: selectedCorte,
              observaciones,
              total_cantidad: totalPiezasEntradaAlma,
              total_segundas: totalPiezasEntradaSegundasAlma,
              areas: areasEntrada,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            articulosCortesEntrada.map((a) => {
              if (a.cantidad != 0) {
                axios
                  .post(
                    URL_ENTRADAS_ALMACEN_ACUMULADOS,
                    {
                      fecha: fechaEntradaAlma,
                      cortes: selectedCorte,
                      cantidad: a.cantidad,
                      segundas: a.segundas,
                      entradasAlmacen: data.data._id,
                      articulosCortes: a.id,
                      articulos: a.idArticulo,
                      tallas: a.idTalla,
                      colores: a.idColor,
                      areas: areasEntrada,
                      ubicacion: a.ubicacion,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Entrada Almacen",
                            detalle: `Corte ${selectedCorte}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Entrada Almacen",
                        detalle: `Corte ${selectedCorte}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
            setValidaBoton(true);
          })
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        })
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  // PDF Procesos

  function PDFProcesosSalida(id, numero) {
    axios
      .get(`${URL_PROCESOS_SALIDA}/corte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .sort((a, b) => (a.idProcesosSalida < b.idProcesosSalida ? 1 : -1))
          .map((a) => {
            return {
              id: a._id,
              catalogosProcesosName: a.catalogoProcesos[0].name,
              proveedoresName: a.proveedores[0].nombre_comercial,
              idProveedor: a.proveedores[0]._id,
              pedido: a.pedidos[0].idPedido,
              idPedido: a.pedidos[0]._id,
              fechaCompromiso: a.fechaCompromiso,
              totalGeneral: a.totalGeneral,
              totalPiezas: a.totalPiezas,
              precio: a.precio,
              numero: a.idProcesosSalida,
              fecha: a.fecha,
              idCorte: a.cortes[0]._id,
              numeroCorte: a.cortes[0].idCorte,
              ordenCorte: a.cortes[0].ordenesCorte[0].numero,
              articulo:
                a.articulos[0].codigo + " / " + a.articulos[0].codigoCliente,
              idArticulo: a.articulos[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = Object.values(arrayTabla);
        setPDFPS(data);
      })
      .catch((err) => {
        console.log(err);
      });
    togglePDFPS();
  }

  async function PDFOCPS(
    id,
    numero,
    proceso,
    maquilero,
    pedido,
    fechaCompromiso,
    totalPiezas,
    precio,
    totalGeneral,
    fecha,
    idArticulo,
    ordenCorte
  ) {
    var fotoDany;
    await axios
      .get(`${process.env.REACT_APP_URL_GETMEDIA}/${idArticulo}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.secure_url != null) {
          fotoDany = data.data.secure_url;
          //  return setFoto(data.data.secure_url)
        } else fotoDany = process.env.PUBLIC_URL + "sinImagen.png";
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`${URL_ARTICULOS_PROCESOS_SALIDA}/proceso/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(async (response) => {
        let allArticulosCorte = response.data;
        let arrayTabla = allArticulosCorte
          .map((a) => {
            return [
              a.articulosPedido[0].articulos[0].codigoCliente +
                " " +
                a.articulosPedido[0].articulos[0].codigo +
                " " +
                a.articulosPedido[0].articulos[0].descripcion,
              a.articulosPedido[0].colores[0].name,
              a.articulosPedido[0].tallas[0].name,
              a.cantidadSalida,
            ];
          })
          .filter(function (el) {
            return el != null;
          });

        const dataPDF = Object.values(arrayTabla);

        await axios
          .get(`${URL_DETALLE_HABILITACION_CORTES}/proceso/${id}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((hab) => {
            let allHabilitacionCorte = hab.data;
            let arrayTablaHab = allHabilitacionCorte
              .map((a) => {
                return [
                  a.habilitacionCortes[0].fecha,
                  a.habilitacion[0].nombre +
                    " " +
                    a.habilitacion[0].descripcion,
                  a.cantidad,
                ];
              })
              .filter(function (el) {
                return el != null;
              });

            const dataPDFHab = Object.values(arrayTablaHab);

            const doc = new jsPDF("landscape");

            var img3 = new Image();
            img3.src = fotoDany;
            doc.addImage(img3, "unknow", 230, 10, 45, 40);

            var img = new Image();
            img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
            doc.addImage(img, "png", 130, 10, 40, 10);
            var img2 = new Image();
            img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
            doc.addImage(img2, "png", 120, 20, 60, 25);
            doc.text(`Maquilero ${maquilero}`, 20, 20);
            doc.setFontSize(12);
            doc.text(`Fecha ${fecha}`, 20, 25);
            doc.text(`Fecha Compromiso ${fechaCompromiso}`, 20, 30);
            doc.text(`Proceso Salida ${proceso}`, 20, 35);
            doc.text(`Orden Corte ${ordenCorte}`, 20, 40);
            doc.text(`Precio $${precio}`, 20, 45);

            doc.autoTable({
              head: [["Articulo", "Color", "Talla", "Cantidad"]],
              body: dataPDF,
              startY: 55,
              foot: [
                [
                  "",
                  "",
                  "Total",
                  new Intl.NumberFormat("en-US").format(totalPiezas),
                ],
              ],
              showFoot: "lastPage",
            });
            doc.text(`Habilitacion`, 15, doc.autoTable.previous.finalY + 10);
            doc.autoTable({
              head: [["Fecha", "Descripcion", "Cantidad"]],
              body: dataPDFHab,
              startY: doc.autoTable.previous.finalY + 13,
              showFoot: "lastPage",
            });
            doc.setFontSize(18);
            var lines = doc.splitTextToSize(`Firma`, 200);
            var lines2 = doc.splitTextToSize(`___________________`, 200);
            doc.text(142, doc.autoTable.previous.finalY + 30, lines);
            doc.text(120, doc.autoTable.previous.finalY + 23, lines2);
            // doc.save(`ProcesoSalida-${numero}.pdf`);
            window.open(doc.output("bloburl"), "_blank");
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function PDFProcesosEntrada(id, numero) {
    axios
      .get(`${URL_PROCESOS_ENTRADA}/corte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .sort((a, b) => (a.idProcesosEntrada < b.idProcesosEntrada ? 1 : -1))
          .map((a) => {
            return {
              id: a._id,
              catalogosProcesosName: a.catalogoProcesos[0].name,
              proveedoresName: a.proveedores[0].nombre_comercial,
              pedido: a.pedidos[0].idPedido,
              totalGeneral: a.totalGeneral,
              totalPiezas: a.totalPiezas,
              totalPiezasFaltantes: a.totalPiezasFaltantes,
              totalPiezasSegundas: a.totalPiezasSegundas,
              precio: a.precio,
              numero: a.idProcesosEntrada,
              fecha: a.fecha,
              articulo:
                a.procesosSalida[0].articulos[0].codigo +
                " / " +
                a.procesosSalida[0].articulos[0].codigoCliente,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = Object.values(arrayTabla);
        setPDFPE(data);
      })
      .catch((err) => {
        console.log(err);
      });
    togglePDFPE();
  }

  async function PDFOCPE(
    id,
    numero,
    proceso,
    maquilero,
    totalPiezas,
    totalPiezasFaltantes,
    totalPiezasSegundas,
    precio,
    fecha,
    totalGeneral
  ) {
    await axios
      .get(`${URL_ARTICULOS_PROCESOS_ENTRADA}/proceso/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosCorte = response.data;
        let arrayTabla = allArticulosCorte
          .map((a) => {
            return [
              a.articulosPedido[0].articulos[0].codigoCliente +
                " " +
                a.articulosPedido[0].articulos[0].codigo +
                " " +
                a.articulosPedido[0].articulos[0].descripcion,
              a.articulosPedido[0].colores[0].name,
              a.articulosPedido[0].tallas[0].name,
              a.cantidadEntrada,
              a.cantidadFaltantes,
              a.cantidadSegundas,
            ];
          })
          .filter(function (el) {
            return el != null;
          });

        const dataPDF = Object.values(arrayTabla);

        const doc = new jsPDF();
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 150, 10, 33, 10);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 160, 23, 25, 25);
        doc.text(`Maquilero ${maquilero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Proceso Entrada ${proceso}`, 20, 30);
        doc.text(`Precio $${precio}`, 20, 35);
        doc.text(
          `Total ${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(totalGeneral)}`,
          20,
          40
        );
        doc.text(
          `Total Cantidad ${new Intl.NumberFormat("en-US", {}).format(
            totalPiezas + totalPiezasFaltantes + totalPiezasSegundas
          )}`,
          20,
          45
        );
        doc.autoTable({
          head: [
            [
              "Articulo",
              "Color",
              "Talla",
              "Cantidad",
              "Faltante Hab",
              "Segundas",
            ],
          ],
          body: dataPDF,
          startY: 50,
          foot: [
            [
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US").format(totalPiezas),
              new Intl.NumberFormat("en-US").format(totalPiezasFaltantes),
              new Intl.NumberFormat("en-US").format(totalPiezasSegundas),
            ],
          ],
          showFoot: "lastPage",
        });
        // doc.save(`ProcesoEntrada-${numero}.pdf`);

        window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_produccion ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.menu_produccion ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/CortesCreate"
                >
                  Nueva Corte
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Corte
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuProduccion"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <h3 align="center">Cortes</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.ordenCorte}</td>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.mesa}</td>
                      <td>{a.cliente}</td>
                      <td>{a.articulo}</td>
                      {/* <td>{a.marca}</td> */}
                      <td>{a.fechaCancelacionPedido}</td>
                      <td>{a.total_cantidad}</td>
                      <td>{a.cantidadPedido}</td>
                      <td>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) =>
                            PDFOC(
                              a.numero,
                              a.fecha,
                              a.ordenCorte,
                              a.mesa,
                              a.total_cantidad,
                              a._id,
                              a.lienzos,
                              a.empalmes,
                              a.retaceria,
                              a.largoTrazo,
                              a.tiempoInicio,
                              a.tiempoFin,
                              a.responsable1,
                              a.responsable2,
                              a.observaciones
                            )
                          }
                        >
                          <i class="far fa-file-pdf"></i>
                        </Button>
                      </td>
                      {a.status == "Abierto" ? (
                        <>
                          <td>
                            <Button
                              color="info"
                              id="Telas"
                              size="sm"
                              onClick={(e) =>
                                procesosSalida(a._id, a.ordenCorte)
                              }
                            >
                              <i class="fas fa-sign-out-alt"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFProcesosSalida(a._id, a.ordenCorte)
                              }
                            >
                              <i class="fas fa-search"></i>
                            </Button>
                          </td>
                          <td>
                            <Button
                              color="primary"
                              id="Telas"
                              size="sm"
                              onClick={(e) =>
                                procesosEntrada(a._id, a.ordenCorte)
                              }
                            >
                              <i class="fas fa-sign-in-alt"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFProcesosEntrada(a._id, a.ordenCorte)
                              }
                            >
                              <i class="fas fa-search"></i>
                            </Button>
                          </td>
                          <td>
                            <Button
                              size="sm"
                              className="btn btn-success"
                              onClick={(e) =>
                                entradaAlmacen(a._id, a.ordenCorte)
                              }
                            >
                              <i class="fas fa-tshirt"></i>
                            </Button>
                          </td>
                        </>
                      ) : (
                        <>
                          <td></td>
                          <td></td>
                          <td></td>
                        </>
                      )}
                      <td>
                        <Status
                          idStatus={a._id}
                          status={a.status}
                          URL_STATUS={process.env.REACT_APP_URL_CORTES}
                        />
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
        <ModalHeader toggle={toggleMail}>
          <h4>Enviar Listado Cortes</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={enviaMail}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios minutos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalProcesosSalida}
        toggle={toggleProcesosSalida}
      >
        <ModalHeader toggle={toggleProcesosSalida}>
          <h4>Procesos Corte {selectedCorteNumero}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Articulo</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
            <Col md={8}>
              <Label className="mr-sm-2">Procesos</Label>
            </Col>
          </Row>
          {articulosUnicos.map((a) => (
            <div key={a.id}>
              <Row>
                <Col md={3}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="text"
                    value={a.articuloNombre}
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="text"
                    value={a.total_cantidad}
                    disabled
                  />
                </Col>
                <Col md={8}>
                  {a.catalogoProcesos.map((p) => (
                    <Button
                      size="sm"
                      className="btn btn-success"
                      onClick={(e) =>
                        funcionProcesosSalida(
                          p._id,
                          p.name,
                          a.idArticulo,
                          a.articuloNombre,
                          a.idPedido,
                          a.numeroPedido,
                          a.fechaCancelacion
                        )
                      }
                    >
                      {p.name}
                    </Button>
                  ))}
                </Col>
              </Row>
            </div>
          ))}
        </ModalBody>
      </Modal>

      <Modal
        size="xl"
        isOpen={modalFuncionProcesosSalida}
        toggle={toggleFuncionProcesosSalida}
      >
        <ModalHeader toggle={toggleFuncionProcesosSalida}>
          <h4>
            Proceso Salida: {nombreProcesosSalida} <br />
            Articulo: {nombreArticulo} <br />
            Pedido: {numeroPedido} <br />
            Fecha Cancelacion: {fechaCancelacion}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveSalidaProceso}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaProceso}
                  required
                  onChange={(e) => {
                    setFechaProceso(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Fecha Compromiso</Label>
                <Input
                  type="date"
                  placeholder="FechaCompromiso"
                  value={fechaCompromisoProceso}
                  required
                  onChange={(e) => {
                    setFechaCompromisoProceso(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Maquilero</Label>
                <Input
                  type="select"
                  placeholder="Maquilero"
                  value={maquileroProceso}
                  required
                  onChange={(e) => {
                    setMaquileroProceso(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      if (a.tipo == "Maquilero") {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Precio</Label>
                <Input
                  // bsSize="sm"
                  name="precio"
                  type="number"
                  placeholder="Precio"
                  value={precioProceso}
                  required
                  onChange={(event) => handleChangeInputPrecio(event)}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={12}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={4}>
                <Label className="mr-sm-2">Talla</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>
            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="color"
                      type="text"
                      value={inputField.colores}
                      disabled
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="talla"
                      type="text"
                      value={inputField.tallas}
                      disabled
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={inputField.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <h4>TOTALES</h4>
              </Col>
              <Col md={2}>
                <h4>{totalPiezas}</h4>
              </Col>
              <Col md={2}>
                <h4>{totalGeneral}</h4>
              </Col>
            </Row>

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      {/* Procesos Entrada */}
      <Modal
        size="xxl"
        isOpen={modalProcesosEntrada}
        toggle={toggleProcesosEntrada}
      >
        <ModalHeader toggle={toggleProcesosEntrada}>
          <h4>Procesos Corte {selectedCorteNumero}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={2}>
              <Label className="negrita">Articulo</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Proceso</Label>
            </Col>
            <Col md={2}>
              <Label className="negrita">Maquilero</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Fecha Salida</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Compromiso</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Precio</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Salida</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Entrada</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Recibir</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Cerrar</Label>
            </Col>
          </Row>
          {articulosUnicosEntrada.map((a) => (
            <div key={a.id}>
              <Row>
                <Col md={2}>
                  {" "}
                  <Label> {a.articuloNombre}</Label>
                </Col>
                <Col md={1}>
                  {" "}
                  <Label>{a.catalogoProcesos}</Label>
                </Col>
                <Col md={2}>
                  {" "}
                  <Label>{a.maquilero}</Label>
                </Col>
                <Col md={1}>
                  {" "}
                  <Label>{a.fecha}</Label>
                </Col>
                <Col md={1}>
                  {" "}
                  <Label>{a.fechaCompromiso}</Label>
                </Col>
                <Col md={1}>
                  {" "}
                  <Label>$ {a.precio}</Label>
                </Col>
                <Col md={1}>
                  {" "}
                  <Label>{a.cantidadSalida}</Label>
                </Col>
                <Col md={1}>
                  {" "}
                  <Label>{a.cantidadEntrada}</Label>
                </Col>
                <Col md={1}>
                  {a.status == "Cerrado" || a.faltantes <= 0 ? undefined : (
                    <Button
                      color="primary"
                      size="sm"
                      className="btn btn-success"
                      onClick={(e) =>
                        funcionProcesosEntrada(
                          a.idCatalogoProcesos,
                          a.catalogoProcesos,
                          a.idArticulo,
                          a.articuloNombre,
                          a.idPedido,
                          a.numeroPedido,
                          a.procesosSalida
                        )
                      }
                    >
                      <i class="fas fa-sign-in-alt"></i>
                    </Button>
                  )}
                </Col>
                <Col md={1}>
                  {a.status == "Cerrado" ? undefined : (
                    <Button
                      color="primary"
                      size="sm"
                      className="btn btn-success"
                      onClick={(e) =>
                        cerrarProcesosEntrada(
                          a.procesosSalida,
                          a.faltantes,
                          a.faltantesHab,
                          a.idMaquilero
                        )
                      }
                    >
                      <i class="fas fa-lock"></i>
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          ))}
        </ModalBody>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalFuncionProcesosEntrada}
        toggle={toggleFuncionProcesosEntrada}
      >
        <ModalHeader toggle={toggleFuncionProcesosEntrada}>
          <h4>
            Proceso: {nombreProcesosEntrada} <br />
            Articulo: {nombreArticulo} <br />
            Pedido: {numeroPedido}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveEntradaProceso}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaProceso}
                  required
                  onChange={(e) => {
                    setFechaProceso(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                <Label>Maquilero</Label>
                <Input
                  type="select"
                  placeholder="Maquilero"
                  value={maquileroProceso}
                  required
                  disabled
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      if (a.tipo == "Maquilero") {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Precio</Label>
                <Input
                  // bsSize="sm"
                  name="precio"
                  type="number"
                  placeholder="Precio"
                  value={precioProceso}
                  required
                  onChange={(event) => handleChangeInputPrecio(event)}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={12}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Talla</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Faltante Hab</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Segundas</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>
            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="color"
                      type="text"
                      value={inputField.colores}
                      disabled
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="talla"
                      type="text"
                      value={inputField.tallas}
                      disabled
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={inputField.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="faltantes"
                      type="number"
                      value={inputField.faltantes}
                      onChange={(event) =>
                        handleChangeInputCantidadFDS(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="segundas"
                      type="number"
                      value={inputField.segundas}
                      onChange={(event) =>
                        handleChangeInputCantidadFDS(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            <Row>
              <Col md={2}></Col>
              <Col md={2}>
                <h4>TOTALES</h4>
              </Col>
              <Col md={2}>
                <h4>{totalPiezas}</h4>
              </Col>
              <Col md={2}>
                <h4>{totalPiezasFaltantes}</h4>
              </Col>
              <Col md={2}>
                <h4>{totalPiezasSegundas}</h4>
              </Col>
              <Col md={2}>
                <h4>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalGeneral)}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md={2}></Col>
              <Col md={2}>
                <h4>TOTAL CANTIDAD</h4>
              </Col>
              <Col md={2}>
                <h4>
                  {totalPiezas + totalPiezasFaltantes + totalPiezasSegundas}
                </h4>
              </Col>
            </Row>
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalFaltante} toggle={toggleFaltante}>
        <ModalHeader toggle={toggleFaltante}></ModalHeader>
        <ModalBody>
          <Form onSubmit={saveFaltantes}>
            <Row>
              <h4>Faltantes Maquileros</h4>
            </Row>
            <Row>
              <Col md={4}>
                <Label>Fecha</Label>
                <Input
                  bsSize="sm"
                  name="fecha"
                  type="date"
                  required
                  value={fechaFaltante}
                  onChange={(event) => {
                    setFechaFaltante(event.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>
                <Label>Faltantes</Label>
                <Input
                  bsSize="sm"
                  name="cantidad"
                  type="number"
                  value={cantidadFaltante}
                  onChange={(event) => {
                    setCantidadFaltante(event.target.value);
                    setTotalFaltante(event.target.value * precioFaltante);
                    setTotalTotalFaltante(
                      event.target.value * precioFaltante + totalFaltanteHab
                    );
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Precio</Label>
                <Input
                  bsSize="sm"
                  name="precio"
                  type="number"
                  value={precioFaltante}
                  onChange={(event) => {
                    setPrecioFaltante(event.target.value);
                    setTotalFaltante(event.target.value * cantidadFaltante);
                    setTotalTotalFaltante(
                      event.target.value * cantidadFaltante + totalFaltanteHab
                    );
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Total</Label>
                <Input
                  bsSize="sm"
                  name="cantidad"
                  type="number"
                  value={totalFaltante}
                  disabled
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>
                <Label>Faltantes Habilitacion</Label>
                <Input
                  bsSize="sm"
                  name="cantidad"
                  type="number"
                  value={cantidadFaltanteHab}
                  onChange={(event) => {
                    setCantidadFaltanteHab(event.target.value);
                    setTotalFaltanteHab(event.target.value * precioFaltanteHab);
                    setTotalTotalFaltante(
                      event.target.value * precioFaltanteHab + totalFaltante
                    );
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Precio</Label>
                <Input
                  bsSize="sm"
                  name="precio"
                  type="number"
                  value={precioFaltanteHab}
                  onChange={(event) => {
                    setPrecioFaltanteHab(event.target.value);
                    setTotalFaltanteHab(
                      event.target.value * cantidadFaltanteHab
                    );
                    setTotalTotalFaltante(
                      event.target.value * cantidadFaltanteHab + totalFaltante
                    );
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Total</Label>
                <Input
                  bsSize="sm"
                  name="cantidad"
                  type="number"
                  value={totalFaltanteHab}
                  disabled
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={8}></Col>
              <Col md={4}>
                <Label>Total por Aplicar</Label>
                <Input
                  bsSize="sm"
                  name="cantidad"
                  type="number"
                  value={totalTotalFaltante}
                  disabled
                />
              </Col>
            </Row>

            <Button type="submit" className="btn btn-success">
              Guardar
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      {/* Entrada Almacen */}

      <Modal
        size="xxl"
        isOpen={modalEntradaAlmacen}
        toggle={toggleEntradaAlmacen}
      >
        <ModalHeader toggle={toggleEntradaAlmacen}>
          <h3>Entrada Almacen</h3>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveEntradaAlma}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaEntradaAlma}
                  required
                  onChange={(e) => {
                    setFechaEntradaAlma(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                <Label>Almacen</Label>
                <Input
                  type="select"
                  value={areasEntrada}
                  required
                  onChange={(e) => {
                    setAreasEntrada(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {areas.map((a) => {
                    if (a.name != "General") {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
                </Input>
              </Col>

              <Col md={3}>
                <Label>Codigo SAT</Label>
                <Input
                  type="select"
                  placeholder="CodigoSAT"
                  value={codigoSAT}
                  required
                  onChange={(e) => {
                    setCodigoSAT(e.target.value);
                  }}
                  >
                    <option value="">Selecciona</option>
                    <option value="53101604">53101604</option>
                    <option value="53102002">53102002</option>
                    <option value="53101504">53101504</option>
                    <option value="53101804">53101804</option>
                    <option value="53101704">53101704</option>
                    <option value="53103100">53103100</option>
                    <option value="53102800">53102800</option>
                    <option value="1010101">1010101</option>
                  </Input>
              </Col>
              <Col md={3}>
                <Label>Codigo Unidad</Label>
                <Input
                  type="string"
                  placeholder="CodigoUnidad"
                  value={codigoUnidad}
                  required
                  onChange={(e) => {
                    setCodigoUnidad(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={12}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Talla</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Segundas</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Ubicacion</Label>
              </Col>
            </Row>
            {articulosCortesEntrada.map((a) => (
              <div key={a.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="articuloNombre"
                      type="text"
                      value={a.articuloNombre}
                      disabled
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="color"
                      type="text"
                      value={a.color}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="talla"
                      type="text"
                      value={a.talla}
                      disabled
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={a.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidadEntradaAlma(a.id, event)
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="segundas"
                      type="number"
                      value={a.segundas}
                      onChange={(event) =>
                        handleChangeInputEntradaSegundasAlma(a.id, event)
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="ubicacion"
                      type="text"
                      value={a.ubicacion}
                      onChange={(event) =>
                        handleChangeInputUbicacion(a.id, event)
                      }
                    />
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            <Row>
              <Col md={5}></Col>
              <Col md={1}>
                <h4>TOTAL</h4>
              </Col>
              <Col md={2}>
                <h4>{totalPiezasEntradaAlma}</h4>
              </Col>
              <Col md={2}>
                <h4>{totalPiezasEntradaSegundasAlma}</h4>
              </Col>
            </Row>

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      {/* PDFPS */}
      <Modal size="xxl" isOpen={modalPDFPS} toggle={togglePDFPS}>
        <ModalHeader toggle={togglePDFPS}>
          <h3>Detalle Salida Procesos</h3>
        </ModalHeader>
        <ModalBody>
          <Table>
            <tr>
              <th>#</th>
              <th>Proceso</th>
              <th>Proveedor</th>
              <th>Articulo</th>
              <th>Pedido</th>
              <th>Fecha Comp.</th>
              <th>Piezas</th>
              <th>Precio</th>
              <th>Total</th>
              <th>Habilitacion</th>
              <th>PDF</th>
            </tr>
            {PDFPS.map((a) => {
              return (
                <tr>
                  <td>{a.numero}</td>
                  <td>{a.catalogosProcesosName}</td>
                  <td>{a.proveedoresName}</td>
                  <td>{a.articulo}</td>
                  <td>{a.pedido}</td>
                  <td>{a.fechaCompromiso}</td>
                  <td>{a.totalPiezas}</td>
                  <td>{a.precio}</td>
                  <td>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 2,
                    }).format(a.totalGeneral)}
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn"
                      color="success"
                      onClick={(e) =>
                        jalaHabilitacion(
                          a.id,
                          a.numero,
                          a.catalogosProcesosName,
                          a.proveedoresName,
                          a.idProveedor,
                          a.pedido,
                          a.idPedido,
                          a.fechaCompromiso,
                          a.totalPiezas,
                          a.precio,
                          a.totalGeneral,
                          a.fecha,
                          a.idCorte,
                          a.numeroCorte
                        )
                      }
                    >
                      <i class="fas fa-tag"></i>
                    </Button>
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={(e) =>
                        PDFOCPS(
                          a.id,
                          a.numero,
                          a.catalogosProcesosName,
                          a.proveedoresName,
                          a.pedido,
                          a.fechaCompromiso,
                          a.totalPiezas,
                          a.precio,
                          a.totalGeneral,
                          a.fecha,
                          a.idArticulo,
                          a.ordenCorte
                        )
                      }
                    >
                      <i class="far fa-file-pdf"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>
        </ModalBody>
      </Modal>

      <Modal size="xxl" isOpen={modalPDFPE} toggle={togglePDFPE}>
        <ModalHeader toggle={togglePDFPE}>
          <h3>Detalle Entrada Procesos</h3>
        </ModalHeader>
        <ModalBody>
          <Table>
            <tr>
              <th>#</th>
              <th>Proceso</th>
              <th>Proveedor</th>
              <th>Articulo</th>
              <th>Pedido</th>
              <th>Fecha</th>
              <th>Piezas</th>
              <th>Faltantes Heb</th>
              <th>Segundas</th>
              <th>Precio</th>
              <th>Total</th>
              <th>PDF</th>
            </tr>
            {PDFPE.map((a) => {
              return (
                <tr>
                  <td>{a.numero}</td>
                  <td>{a.catalogosProcesosName}</td>
                  <td>{a.proveedoresName}</td>
                  <td>{a.articulo}</td>
                  <td>{a.pedido}</td>
                  <td>{a.fecha}</td>
                  <td>{a.totalPiezas}</td>
                  <td>{a.totalPiezasFaltantes}</td>
                  <td>{a.totalPiezasSegundas}</td>
                  <td>
                    {" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 2,
                    }).format(a.precio)}
                  </td>
                  <td>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 2,
                    }).format(a.totalGeneral)}
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={(e) =>
                        PDFOCPE(
                          a.id,
                          a.numero,
                          a.catalogosProcesosName,
                          a.proveedoresName,
                          a.totalPiezas,
                          a.totalPiezasFaltantes,
                          a.totalPiezasSegundas,
                          a.precio,
                          a.fecha,
                          a.totalGeneral
                        )
                      }
                    >
                      <i class="far fa-file-pdf"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalHabilitacion} toggle={toggleHabilitacion}>
        <ModalHeader toggle={toggleHabilitacion}>
          <h3>Entregar Habilitacion</h3>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveHabilitacion}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={fechaHab}
                  onChange={(event) => {
                    setFechaHab(event.target.value);
                  }}
                ></Input>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <Label className="mr-sm-2">Material</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
            </Row>

            {inputFieldsHabilitacion.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={8}>
                    <Input
                      bsSize="sm"
                      name="idHabilitacion"
                      type="select"
                      value={inputField.idHabilitacion}
                      onChange={(event) => {
                        handleChangeInputHabilitacion(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {habilitacion
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.nombre} {a.descripcion}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="string"
                      value={inputField.cantidad}
                      onChange={(event) => {
                        handleChangeInputHabilitacion(inputField.id, event);
                      }}
                    ></Input>
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFieldsHabilitacion.length === 1}
                      onClick={() =>
                        handleRemoveFieldsHabilitaccion(inputField.id)
                      }
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsHabilitacion}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoCortes;
