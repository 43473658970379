import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function DevolucionesProveedoresHabCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;
  const URL_DEVOLUCIONES_PROVEEDORES = process.env.REACT_APP_URL_DEVOLUCIONES_PROVEEDORES;

  const URL_INVENTARIOS_TELAS = process.env.REACT_APP_URL_INVENTARIOS_TELAS;
  const URL_TELAS_DEVOLUCIONES_PROVEEDORES =
    process.env.REACT_APP_URL_TELAS_DEVOLUCIONES_PROVEEDORES;

  const URL_DEVOLUCIONES_PROVEEDORES_ACUMULADOS =
    process.env.REACT_APP_URL_DEVOLUCIONES_PROVEEDORES_ACUMULADOS;

    let date = new Date();
    let hoy = moment(date).format("YYYY-MM-DD");

  const [compras, setCompras] = useState([]);
  const [selectedCompra, setSelectedCompra] = useState("");

  const [proveedor, setProveedor] = useState("");
  const [idProveedor, setIdProveedor] = useState("");
  const [fecha, setFecha] = useState(hoy);
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [total_piezas, setTotalPiezas] = useState(0);
  const [total_general, setTotalGeneral] = useState(0);
  const [iva, setIva] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [impuestos, setImpuestos] = useState(0);


  const [telasDevolucion, setTelasDevolucion] = useState([]);
  const [fecha_compra, setFechaCompra] = useState("");
  const [proveedorPedido, setProveedorPedido] = useState("");
  const [total_kilos_pedido, setTotalKilosPedido] = useState(0);
  const [total_metros_pedido, setTotalMetrosPedido] = useState(0);
  const [total_piezas_pedido, setTotalPiezasPedido] = useState(0);
  const [total_general_pedido, setTotalGeneralPedido] = useState(0);

  const [idsInventarioTelas, setIdsInventarioTelas] = useState([]);
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      // id: uuidv4(),
      // idInventarioTelas: "",
      // telas: "",
      // idTela: "",
      // unidad: "",
      // colores: "",
      // cantidad: 0,
      // costo:0
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_COMPRAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCompras = res.data;
        setCompras(allCompras);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const saveDevolucion = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();


    let totalRollos =
      inputFields.length 

    try {
      await axios
        .post(
          URL_DEVOLUCIONES_PROVEEDORES,
          {
            fecha,
            compras: selectedCompra,
            user: user.id,
            observaciones,
            subTotal:subTotal.toFixed(2),
            iva: iva.toFixed(2),
            total_general: total_general.toFixed(2),
            total_cantidad: parseFloat(total_metros) + parseFloat(total_kilos) + parseFloat(total_piezas),
            aplicada:"No",
            proveedores:idProveedor
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.cantidad > 0) {
              axios
                .post(
                  `${URL_DEVOLUCIONES_PROVEEDORES_ACUMULADOS}/devolucionHabilitacion`,
                  {
                    fecha,
                    cantidad: a.cantidad,
                    compras: selectedCompra,
                    devolucionesProveedores: data.data._id,
                    proveedores: idProveedor,
                    habilitacion: a.idHabilitacion,
                    costo: a.precio
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                ).then(()=>{
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Devolucion",
                          detalle: `Compra ${selectedCompra}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                }).catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } else {
              totalRollos = totalRollos - 1;
              if (totalRollos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Devolucion",
                      detalle: `Compra ${selectedCompra}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });

        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantidad = parseFloat(event.target.value);
        let totalParcial = parseFloat(event.target.value) * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let mts = inputFields.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let PZ = inputFields.filter((e) => e.unidad == "Piezas");
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TPZ = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TPZ);

    let totalDinero = inputFields.map((c) => parseFloat(c.total));
    let dinero = totalDinero.reduce((t, total, index) => t + total, 0);
    setSubTotal(dinero);

    if (impuestos != 0) {
      setIva((impuestos * dinero) / 100);
    } else {
      setIva(0);
    }
    let totTemp = (impuestos * dinero) / 100 + dinero;

    setTotalGeneral(totTemp);
  }


  function jalaCompra(e) {
    setInputFields([])
    setSelectedCompra(e);

    axios
      .get(`${URL_COMPRAS_ACUMULADOS}/compra/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosCompra = res.data;
        const arrayArt = allArticulosCompra.map((a) => {
          return {
            id: uuidv4(),
            idHabilitacion: a.habilitacion[0]._id,
            habilitacion: a.habilitacion[0].nombre + " " + a.habilitacion[0].descripcion,
            unidad: a.habilitacion[0].unidad,
            cantidadComprada: a.cantidad,
            cantidad:0,
            precio: a.telasOC[0].precio,
            total: 0,
          };
        });
        // console.log("hola", arrayArt)
        setInputFields(arrayArt);
      })
      .catch((err) => {
        console.log(err);
      });



    compras.map((a) => {
      if (a._id == e) {
        setFechaCompra(a.fecha);
        setTotalKilosPedido(a.total_kilos);
        setTotalMetrosPedido(a.total_metros);
        setTotalPiezasPedido(a.total_piezas);
        setTotalGeneralPedido(a.total_general);
        setProveedor(a.ordenesCompra[0].proveedores[0].razon_social);
        setIdProveedor(a.ordenesCompra[0].proveedores[0]._id);
        if(a.iva > 0){
          setImpuestos(16)
        }

      }
    });
  }

console.log("hola", inputFields)
  return (
    <>
      <Header />
      <br />
      <br />
      {user.devolucionesProveedores_create ? (
        <div className="card container col-10">
          <h3 align="center">Nueva Devolucion Habilitacion</h3>
          <Form>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Compra</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedCompra}
                  required
                  onChange={(e) => jalaCompra(e.target.value)}
                >
                  <option value="0">Selecciona un Compra</option>
                  {compras
                    .sort((a, b) => (a.idCompras < b.idCompras ? 1 : -1))
                    .map((a) => {
                      if(a.ordenesCompra[0].tipo == "Habilitacion")
                      return (
                        <option value={a._id}>
                          {a.idCompras}{" / "}
                          {a.ordenesCompra[0].proveedores[0].nombre_comercial}
                        </option>
                      );
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Fecha Compra</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha_compra}
                  required
                  disabled
                  onChange={(e) => {
                    setFechaCompra(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Proveedor</Label>
                <Input
                  type="text"
                  placeholder="Proveedor"
                  value={proveedor}
                  required
                  disabled
                  onChange={(e) => {
                    setProveedor(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              {/* <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                  mts.
                </h4>
              </Col> */}
            </Row>

            {/* Tabla Telas */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Habilitacion</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Compra</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="habilitacion"
                      type="string"
                      value={inputField.habilitacion}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidadComprada"
                      type="number"
                      value={inputField.cantidadComprada}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
                      value={inputField.precio}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={inputField.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      value={inputField.total}
                      disabled
                    ></Input>
                  </Col>

                  
                </Row>
              </div>
            ))}
            <Row>
              {/* <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                  mts.
                </h4>
              </Col> */}
            </Row>
            {/* Termina Tabla Telas */}

            <br />
          </Form>
          <Row>
            <Col>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  onClick={saveDevolucion}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
              <Button
                href="/ListadoDevolucionesProveedores"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          <br />
           <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default DevolucionesProveedoresHabCreate;
