import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Form, Button, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function OrdenesCompraCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_TELAS = process.env.REACT_APP_URL_TELAS;
  const URL_HABILITACION = process.env.REACT_APP_URL_HABILITACION;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  // const URL_EXPLOSION_GLOBAL = process.env.REACT_APP_URL_EXPLOSION_GLOBAL;
  // const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_TELAS_OC = process.env.REACT_APP_URL_TELAS_OC;
  
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [telas, setTelas] = useState([]);
  const [habilitacion, setHabilitacion] = useState([]);
  const [colores, setColores] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  // const [clientes, setClientes] = useState([]);
  // const [pedidos, setPedidos] = useState([]);
  // const [selectedCliente, setSelectedCliente] = useState("");
  // const [selectedPedido, setSelectedPedido] = useState("");

  const [fecha, setFecha] = useState(hoy);
  const [plazo, setPlazo] = useState(0);
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  // const [fecha_cancelacion_pedido, setFechaCancelacionPedido] = useState("");
  // const [fecha_creacion_prepedido, setFechaCreacionPrePedido] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [empresa, setEmpresa] = useState("Palassi SA de CV");
  const [total_general, setTotalGeneral] = useState(0);
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [total_piezas, setTotalPiezas] = useState(0);
  // const [pendFechaConf, setPendFechaConf] = useState("");

  const [tipo, setTipo] = useState("");
  const [impuestos, setImpuestos] = useState("");

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      telas: "",
      habilitacion:"",
      unidad: "",
      colores: "",
      // arrayColoresTela:[],
      cantidad: 0,
      precio: 0,
      descuento: 0,
      total: 0,
      observaciones: "NA"
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTelas = res.data;
        setTelas(allTelas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_HABILITACION, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allHabilitacion = res.data;
        setHabilitacion(allHabilitacion);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
      // axios
      //   .get(URL_CLIENTES, {
      //     headers: {
      //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      //     },
      //   })
      //   .then((res) => {
      //     const allClientes = res.data;
      //     setClientes(allClientes);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
  }, []);


  const saveOrdenCompra = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    if(tipo=="Tela"){
    try {
      await axios
        .post(
          URL_ORDENES_COMPRA,
          {
            fecha,
            proveedores: selectedProveedor,
            // clientes: selectedCliente,
            plazo,
            fecha_compromiso,
            // fecha_cita: "0001-01-01",
            // fecha_cancelacion_pedido,
            // fecha_creacion_prepedido,
            total_general,
            observaciones,
            total_kilos,
            total_metros,
            total_piezas,
            tipo,
            impuestos,
            empresa,
            // pendFechaConf
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          // if(tipo=="Tela"){
            inputFields.map((a) => {
              axios.post(
                URL_TELAS_OC,
                {
                  ordenesCompra: data.data._id,
                  telas: a.telas,
                  colores: a.colores,
                  cantidad: parseFloat(a.cantidad),
                  recibido: 0,
                  pendiente_recibir: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  descuento: parseFloat(a.descuento),
                  total: parseFloat(a.total),
                  observaciones: a.observaciones,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              );
            });
            axios.post(
              URL_LOGS,
              {
                tipo: "Crear Orden de Compra",
                detalle: `${selectedProveedor} ${total_general}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            Swal.fire("Good job!", `Creado con exito, OC ${data.data.idOrdenCompra}`, `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);

        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  }else
  try {
    await axios
      .post(
        URL_ORDENES_COMPRA,
        {
          fecha,
          proveedores: selectedProveedor,
          // clientes: selectedCliente,
          plazo,
          fecha_compromiso,
          // fecha_cita: "0001-01-01",
          // fecha_cancelacion_pedido,
          // fecha_creacion_prepedido,
          total_general,
          observaciones,
          total_kilos,
          total_metros,
          total_piezas,
          tipo,
          impuestos,
          empresa,
          autorizado: "Si",
          // pendFechaConf
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((data) => {
        // if(tipo=="Tela"){
          inputFields.map((a) => {
            axios.post(
              URL_TELAS_OC,
              {
                ordenesCompra: data.data._id,
                habilitacion:a.habilitacion,
                cantidad: parseFloat(a.cantidad),
                recibido: 0,
                pendiente_recibir: parseFloat(a.cantidad),
                precio: parseFloat(a.precio),
                descuento: parseFloat(a.descuento),
                total: parseFloat(a.total),
                observaciones: a.observaciones,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
          });
          axios.post(
            URL_LOGS,
            {
              tipo: "Crear Orden de Compra",
              detalle: `${selectedProveedor} ${total_general}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
          Swal.fire("Good job!", `Creado con exito, OC ${data.data.idOrdenCompra}`, `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
      });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      footer: `${error.response.data}`,
    });
    console.log(error);
  }
  };
  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length-1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        telas: ultimo.telas,
        habilitacion: ultimo.habilitacion,
        unidad: ultimo.unidad,
        colores: ultimo.colores,
        // arrayColoresTela: ultimo.arrayColoresTela,
        cantidad: 0,
        precio: ultimo.precio,
        descuento: ultimo.descuento,
        total: 0,
        observaciones: "NA"
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter(e=>e.unidad == "Metros")
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter(e=>e.unidad == "Kilos")
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let PZ = values.filter(e=>e.unidad == "Piezas")
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TP);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  function BuscaProveedor(event){
    proveedores.map((a)=>{
      if(a._id == event.target.value){
        setSelectedProveedor(event.target.value)
        setPlazo(a.plazo)
      }
    })
  }

  function BuscaTela(id, event){
    telas.map((a)=>{
      if(a._id == event.target.value){
        let idTela = a._id
        let unidadTela = a.unidad
        let coloresTela = a.colores
        let costo = a.costo
        handleChangeInputTela(id, idTela, unidadTela, coloresTela, costo)
      }
    })
  }

  const handleChangeInputTela = (id, idTela, unidadTela, coloresTela, costo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.telas = idTela;
        i.unidad = unidadTela
        // i.arrayColoresTela = coloresTela
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
        i.precio = costo
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  function BuscaHabilitacion(id, event){
    habilitacion.map((a)=>{
      if(a._id == event.target.value){
        let idHabilitacion = a._id
        let unidadHabilitacion = a.unidad
        let costo = a.costo
        handleChangeInputHabilitacion(id, idHabilitacion, unidadHabilitacion, costo)
      }
    })
  }

  const handleChangeInputHabilitacion = (id, idHabilitacion, unidadHabilitacion, costo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.habilitacion = idHabilitacion;
        i.unidad = unidadHabilitacion
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
        i.precio = costo
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };


  function ActualizaTotales(){
        let mts = inputFields.filter(e=>e.unidad == "Metros")
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetros(TM);
        let KG = inputFields.filter(e=>e.unidad == "Kilos")
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilos(TK);
        let PZ = inputFields.filter(e=>e.unidad == "Piezas")
        let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
        let TP = totalPZ.reduce((t, total, index) => t + total, 0);
        setTotalPiezas(TP);
        let importes = inputFields.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneral(TG);
  }

//   function jalaCliente(idCliente) {
//     setSelectedCliente(idCliente);

//     axios
//       .get(`${URL_PEDIDOS}Cliente/${idCliente}`, {
//         headers: {
//           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//         },
//       })
//       .then((res) => {
//         const allPedidos = res.data;
//         setPedidos(allPedidos);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }
// console.log("hola",pedidos)

// function jalaPedido(idPedido) {
//   setSelectedPedido(idPedido);

//   axios
//     .get(`${URL_EXPLOSION_GLOBAL}Pedido/${idPedido}`, {
//       headers: {
//         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//       },
//     })
//     .then((res) => {
//       const allPedidos = res.data;
//       if(allPedidos.length > 0){
//       let arrayTabla = allPedidos
//           .map((a) => {
//             return {
//               id: a._id,
//               telas: a.telas[0]._id,
//               habilitacion: "",
//               unidad: a.telas[0].unidad,
//               colores: a.colores[0]._id,
//               cantidad: a.cantidad,
//               precio: a.telas[0].costo,
//               descuento: 0,
//               total: a.telas[0].costo * a.cantidad,
//               observaciones: "NA"
//             };
//           })
//           .filter(function (el) {
//             return el != null;
//           });

//         let dataFinal = Object.values(arrayTabla);
//         setInputFields(dataFinal);
         
//         let mts = dataFinal.filter(e=>e.unidad == "Metros")
//         let totalMts = mts.map((c) => parseFloat(c.cantidad));
//         let TM = totalMts.reduce((t, total, index) => t + total, 0);
//         setTotalMetros(TM);
//         let KG = dataFinal.filter(e=>e.unidad == "Kilos")
//         let totalKG = KG.map((c) => parseFloat(c.cantidad));
//         let TK = totalKG.reduce((t, total, index) => t + total, 0);
//         setTotalKilos(TK);
//         let PZ = dataFinal.filter(e=>e.unidad == "Piezas")
//         let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
//         let TP = totalPZ.reduce((t, total, index) => t + total, 0);
//         setTotalPiezas(TP);
//         let importes = dataFinal.map((c) => parseFloat(c.total));
//         let TG = importes.reduce((t, total, index) => t + total, 0);
//         setTotalGeneral(TG);
//         }
//         else{
//           setInputFields([
//             {
//               id: uuidv4(),
//               telas: "",
//               habilitacion:"",
//               unidad: "",
//               colores: "",
//               // arrayColoresTela:[],
//               cantidad: 0,
//               precio: 0,
//               descuento: 0,
//               total: 0,
//               observaciones: "NA"
//             },
//           ])
//         }
     
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.oc_create ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Orden de Compra</h3>
          <Form onSubmit={saveOrdenCompra}>
            <Row>
            <Col md={2}>
                <Label>Tipo</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={tipo}
                  required
                  onChange={(e) => {
                    setTipo(e.target.value)
                    setInputFields([
                      {
                        id: uuidv4(),
                        telas: "",
                        habilitacion:"",
                        unidad: "",
                        colores: "",
                        // arrayColoresTela:[],
                        cantidad: 0,
                        precio: 0,
                        descuento: 0,
                        total: 0,
                        observaciones: "NA"
                      },
                    ])
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Tela">Tela</option>
                  <option value="Habilitacion">Habilitacion</option>
                </Input>
              </Col>
              <Col md={2}>
                <Label>Fecha Orden Compra</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  disabled
                  // required
                  // onChange={(e) => {
                  //   setFecha(e.target.value);
                  // }}
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(event) => {
                    // setSelectedProveedor(e.target.value);
                    BuscaProveedor(event)
                  }}
                >
                  <option value="0">Selecciona</option>
                  {proveedores
                    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                    .map((a) => {
                      if(tipo == a.telaHab){
                      return <option value={a._id}>{a.nombre_comercial}</option>;
                    }
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>Plazo</Label>
                <Input
                  type="text"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Fecha Compromiso</Label>
                <Input
                  type="date"
                  placeholder="Fecha Compromiso"
                  value={fecha_compromiso}
                  required
                  onChange={(e) => {
                    setFechaCompromiso(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Impuestos</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={impuestos}
                  required
                  onChange={(e) => {
                    setImpuestos(e.target.value)
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
              {/* <Col md={2}>
                <Label>Fecha Cita Recibo</Label>
                <Input
                  type="date"
                  placeholder="Fecha Cita"
                  value={fecha_cita}
                  required
                  onChange={(e) => {
                    setFechaCita(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Fecha Cancelacion Pedido</Label>
                <Input
                  type="date"
                  placeholder="Fecha Cancelacion"
                  value={fecha_cancelacion_pedido}
                  required
                  onChange={(e) => {
                    setFechaCancelacionPedido(e.target.value);
                  }}
                />
              </Col>
              </Row>
              <Row>
              <Col md={2}>
                <Label>Fecha Creacion PrePedido</Label>
                <Input
                  type="date"
                  placeholder="Fecha Creacion"
                  value={fecha_creacion_prepedido}
                  required
                  onChange={(e) => {
                    setFechaCreacionPrePedido(e.target.value);
                  }}
                />
              </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Pendiente Fecha Conf</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={pendFechaConf}
                      required
                      onChange={(e) => {
                        setPendFechaConf(e.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </Input>
                  </Col> */}
              {/* <Col md={2}>
                <Label className="mr-sm-2">Cliente</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedCliente}
                  required
                  onChange={(e) => {
                    jalaCliente(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {clientes
                    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre_comercial}</option>;
                    })}
                </Input>
              </Col>
                    {tipo == "Tela" ?(
                        <Col md={2}>
                        <Label className="mr-sm-2">Pedido</Label>
                        <Input
                          className="col-sm-12"
                          type="select"
                          value={selectedPedido}
                          required
                          onChange={(e) => {
                            jalaPedido(e.target.value);
                          }}
                        >
                          <option value="0">Selecciona</option>
                          {pedidos
                            .sort((a, b) => (a.idPedido < b.idPedido ? 1 : -1))
                            .map((a) => {
                              return <option value={a._id}>{a.idPedido}</option>;
                            })}
                        </Input>
                        </Col>
                    ): undefined} */}
            </Row>
            <Row>
              <Col md={3}>
                <Label>Empresa</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={empresa}
                  required
                  onChange={(e) => {
                    setEmpresa(e.target.value)
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Palassi SA de CV">Palassi SA de CV</option>
                </Input>
              </Col>
              <Col md={5}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
              <h4 id="logoutBoton">
              TOTALES {total_kilos} kg. / {total_metros} mts. / {total_piezas} pzas. / {"$" + new Intl.NumberFormat("en-US").format(total_general)}
              </h4>
              </Col>
            </Row>

            {/* Tabla Telas */}

              <Row>
                {tipo=="Tela"?(
                <Col md={2}>
                <Label className="mr-sm-2">Telas</Label>
              </Col>
                ):(
                  <Col md={2}>
                  <Label className="mr-sm-2">Habilitacion</Label>
                </Col>
                )}

                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>

                {tipo=="Tela"?(
                  <Col md={1}>
                  <Label className="mr-sm-2">Colores</Label>
                </Col>
                  ):undefined}

                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Precio</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Descuento</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Colores</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Total</Label>
                </Col>
              </Row>

              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    {tipo == "Tela"?(
                    <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="telas"
                      type="select"
                      value={inputField.telas}
                      required
                      onChange={(event)=>{BuscaTela(inputField.id, event)}}
                    >
                      <option value="">Selecciona</option>
                      {telas
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.nombre} / {a.codigo} / {a.tiposTelas[0].name}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                    ):(
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="habilitacion"
                      type="select"
                      value={inputField.habilitacion}
                      required
                      onChange={(event)=>{BuscaHabilitacion(inputField.id, event)}}
                    >
                      <option value="">Selecciona</option>
                      {habilitacion
                        .map((a) => {
                          return (
                            <option value={a._id}>
                               {a.nombre} / {a.descripcion}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                    )}
                    <Col md={1}>
                        <Input
                         bsSize="sm"
                          name="unidad"
                          type="string"
                          value={inputField.unidad}
                          disabled
                        >
                        </Input>
                      </Col>
                      {tipo=="Tela"?(
                    <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="colores"
                      type="select"
                      value={inputField.colores}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    >
                      <option value="">Selecciona</option>
                      {/* {inputField.arrayColoresTela */}
                      {colores
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                      ):undefined}


                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={inputField.precio}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="descuento"
                        type="number"
                        placeholder="Descuento"
                        value={inputField.descuento}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="observaciones"
                      type="text"
                      value={inputField.observaciones}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="Total"
                        value={inputField.total}
                        disabled
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
              <Col md={10}>
              <h4 id="logoutBoton">
              TOTALES {total_kilos} kg. / {total_metros} mts. /  {total_piezas} pzas. / {"$" + new Intl.NumberFormat("en-US").format(total_general)}
              </h4>
              </Col>
              </Row>
            {/* Termina Tabla Telas */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoOrdenesCompra"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        </div>
      ) : undefined}
    </>
  );
}

export default OrdenesCompraCreate;
